import { ReactElement } from "react";
import { Button } from "@/components/Button/Button";
import { StyledButtonGroupContainer } from "@/components/ButtonGroup/ButtonGroup.style";

interface ButtonProps {
  name: string;
  text: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (name: string) => void;
  active: boolean;
}
interface ButtonGroupProps {
  buttons: ButtonProps[];
}

export const ButtonGroup = ({ buttons }: ButtonGroupProps): ReactElement => (
  <StyledButtonGroupContainer>
    {buttons.map(({ name, text, onClick, active }, i) => (
      <Button
        name={name}
        onClick={onClick}
        key={`${name}-${i}`}
        active={active}
        toggle={{ enabled: true, isToggled: active }}
      >
        {text}
      </Button>
    ))}
  </StyledButtonGroupContainer>
);
