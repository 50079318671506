import styled from "styled-components";

export const StyledButtonGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  button {
    margin: 0 0.25rem;
  }
`;
