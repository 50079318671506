import React, { ReactElement } from "react";

interface ConditionalWrapperProps {
  wrapper: React.FC<ReactElement>;
  condition: boolean;
  children: ReactElement;
}

export const ConditionalWrapper = ({
  children,
  condition,
  wrapper,
}: ConditionalWrapperProps) => {
  return condition ? wrapper(children) : children;
};
