export const zeroPadTo2Digits = (num: number) => `${num}`.padStart(2, "0");

export const numberWithinRange = (num: number, min: number, max: number) => {
  if (num < min) return min;
  else if (num > max) return max;
  return num;
};

export const percentage = (value: number, total: number, decimalPlaces = 2) => {
  if (total === 0) return 0;

  const percentage = (value / total) * 100;
  return +percentage.toFixed(decimalPlaces);
};

export const homeAndAwayValuesToPercentage = (
  home: number,
  away: number
): { home: number; away: number } => {
  const total = home + away;

  return {
    home: percentage(home, total, 1),
    away: percentage(away, total, 1),
  };
};

export const wholeNumberOr1DecimalPlace = (num: number): string =>
  num % 1 ? num.toFixed(1) : `${num}`;

export const wholeNumberOr2DecimalPlaces = (num: number): string =>
  num % 1 ? num.toFixed(2) : `${num}`;

export const twoDecimalFloor = (num: number): string => {
  return (Math.floor(num * 100) / 100).toFixed(2);
};

export const isValidIntegerOrDecimal = (value: string): boolean => {
  const numberRegex = /^[.]?\d+(?:[.]\d*?)?$/g;
  const emptyRegex = /$^/g;

  if (!value.match(numberRegex) && !value.match(emptyRegex)) {
    return false;
  }
  if (value.match(/\./g)) {
    const [, decimal] = value.split(".");

    if (decimal?.length > 2) {
      return false;
    }
  }

  return true;
};

export const fixedTo1DecimalPlace = (num: number): string => num.toFixed(1);

export const calculateStakeReturnsFromOdds = (
  stake: number,
  numerator: number,
  denominator: number
): number => {
  return stake + (stake * numerator) / denominator;
};

export const convertNumberToOrdinal = (
  num: number | null | undefined
): string | null | undefined => {
  if (num === 0 || num === null || num === undefined) {
    return null;
  }

  const suffix = ["th", "st", "nd", "rd"];
  const value = num % 100;
  return num + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
};

export const normalizeToDecimal = (decimalValue: string | number): number => {
  return typeof decimalValue === "string"
    ? parseFloat(decimalValue)
    : decimalValue;
};
