import { MarketType, MarketName } from "@/types/api/market";

export const MATCH_RESULT = "1x2";
export const OU25 = "OU25";
export const MATCH_RESULT_BTTS = "1x2BTTS";
export const BTTS = "BTTS";
export const FIRST_GOALSCORER = "FGS";
export const ANYTIME_GOALSCORER = "AGS";
export const PLAYER_TO_BE_CARDED = "PTBC";
export const SHOTS_ON_TARGET = "P1+SOT";

export const TEAM_MARKETS: MarketType[] = [
  OU25,
  MATCH_RESULT,
  MATCH_RESULT_BTTS,
  BTTS,
];

export const PLAYER_MARKETS: MarketType[] = [
  FIRST_GOALSCORER,
  ANYTIME_GOALSCORER,
  PLAYER_TO_BE_CARDED,
  SHOTS_ON_TARGET,
];

export const marketsMapping = new Map<MarketType, MarketName>([
  [OU25, "Over 2.5 Goals"],
  [MATCH_RESULT_BTTS, "Match Result & BTTS"],
  [MATCH_RESULT, "Match Result"],
  [BTTS, "Both Teams To Score"],
  [FIRST_GOALSCORER, "First Goalscorer"],
  [ANYTIME_GOALSCORER, "Anytime Goalscorer"],
  [PLAYER_TO_BE_CARDED, "To Be Carded"],
  [SHOTS_ON_TARGET, "1+ Shots On Target"],
]);
