import React, {
  Dispatch,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import { competitionLogoSVG, idToTeamBadgeImageUrl } from "@/lib/media";
import { ContentSmartAccaCompetitionWidget } from "@/types/api/content";
import { ButtonGroup } from "@/components/ButtonGroup/ButtonGroup";
import {
  SmartAccaCompetitionType,
  SmartAccaCompetitionResult,
  SmartAccaCompetitionTeams,
} from "@/types/api/smartAcca";
import { getSmartAccaCompetitionWidgetData } from "@/lib/http/smartAcca";
import SmartAccaLogo from "@/public/images/SmartAccaLogo.png";
import SmartAccaLogoBlack from "@/public/images/SmartAccaLogoBlack.png";
import {
  filterPlayerMarketByTeam,
  validateSmartAccaMarket,
} from "@/lib/smartAcca";
import {
  ANYTIME_GOALSCORER,
  BTTS,
  FIRST_GOALSCORER,
  MATCH_RESULT,
  MATCH_RESULT_BTTS,
  OU25,
  PLAYER_TO_BE_CARDED,
  SHOTS_ON_TARGET,
} from "@/constants/markets";
import { capitaliseFirstCharacterInString } from "@/lib/string";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import {
  BetSlipActions,
  BetSlipState,
  CommonAndEventIdPair,
} from "@/types/betslip";
import styles from "@/components/SmartAccaCompetition/SmartAccaCompetition.module.scss";
import { fixedTo1DecimalPlace } from "@/lib/number";
import AngleDownIcon from "@/components/icons/AngleDownIcon";
import AngleUpIcon from "@/components/icons/AngleUpIcon";
import { useDarkMode } from "@/hooks/useDarkMode";
import { Option, Select } from "@/components/Select/Select";
import { getFormattedOdds } from "@/lib/odds";
import { FP_BRAND } from "@/constants/brands";
import { getActiveBrand } from "@/lib/brand";
import { ConditionalWrapper } from "../ConditionalWrapper/ConditionalWrapper";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";

type SmartAccaCompetitionPropsWithoutData = Pick<
  ContentSmartAccaCompetitionWidget,
  "competitionId" | "market" | "type"
>;

export type SmartAccaCompetitionProps = SmartAccaCompetitionPropsWithoutData & {
  results: SmartAccaCompetitionResult[];
  teams: SmartAccaCompetitionTeams[];
};

export const dynamicTableDataHeadings = new Map<string, string>([
  [MATCH_RESULT, "Won"],
  [BTTS, "BTTS"],
  [OU25, "Over 2.5"],
  [MATCH_RESULT_BTTS, "Win & BTTS"],
  [ANYTIME_GOALSCORER, "Scored"],
  [FIRST_GOALSCORER, "Scored First"],
  [SHOTS_ON_TARGET, "1+ SOT"],
  [PLAYER_TO_BE_CARDED, "Carded"],
]);

export const SmartAccaCompetition = ({
  competitionId,
  market,
  results,
  type,
  teams,
}: SmartAccaCompetitionProps): ReactElement => {
  const validType: SmartAccaCompetitionType = type || "team";
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const { activeCommonAndEventIdPairs, oddsFormat } = useContext(
    BetSlipStateContext
  ) as BetSlipState;
  const [activeMarket, setActiveMarket] = useState<string>(
    validateSmartAccaMarket({ type: validType, market })
  );
  // Manages how many rows to show
  const [tableMinimised, toggleTableMinimised] = useState<boolean>(true);
  // Stores results passed as props or updated through useEffect
  const [tableData, setTableData] =
    useState<SmartAccaCompetitionResult[]>(results);
  // Allows filtering by team for player widget
  const [activeTeam, setActiveTeam] = useState("all");
  // Stores teams passed as props or updated through useEffect
  const [currentTeams, setCurrentTeams] = useState(teams);
  // Needed to select the correct SmartAcca Logo
  const [colourMode] = useDarkMode();
  // Used to scroll to the top of the widget after clicking "show less"
  const smartAccaRef = useRef<HTMLDivElement>(null);
  const dynamicTableHeading = dynamicTableDataHeadings.get(activeMarket);
  const minimisedRowCount = 5;
  const capitalisedValidType = capitaliseFirstCharacterInString(validType);

  const tableHeaders = [
    capitalisedValidType,
    "%",
    "Matches",
    dynamicTableHeading ? dynamicTableHeading : "",
    "Fixture",
    "Odds",
  ];

  const scrollToRef = () => {
    if (smartAccaRef && smartAccaRef.current) {
      window.scrollTo({
        top: smartAccaRef.current.offsetTop - 200,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (competitionId) {
      const getData = async () => {
        const { results, teams } = await getSmartAccaCompetitionWidgetData({
          competitionId,
          type: validType,
          market: activeMarket,
        });

        if (results) {
          setTableData(results);
        }

        if (validType === "player" && teams) {
          setCurrentTeams(teams);
        }
      };

      getData();
    }
  }, [activeMarket, competitionId, validType]);

  const onClickHandler = (name: string) => {
    setActiveMarket(name);
  };

  const onSelection = (e: Option) => {
    setActiveTeam(e.id);
  };

  const toggleTableData = () => {
    const updatedToggleMinimised = !tableMinimised;
    toggleTableMinimised(updatedToggleMinimised);
    if (updatedToggleMinimised) scrollToRef();
  };

  // Passed to ButtonGroup
  const teamButtons = [
    {
      name: "1x2",
      text: "Match Result",
      onClick: onClickHandler,
      active: activeMarket === "1x2",
    },
    {
      name: "BTTS",
      text: "Both Teams To Score",
      onClick: onClickHandler,
      active: activeMarket === "BTTS",
    },
    {
      name: "OU25",
      text: "Over 2.5 goals",
      onClick: onClickHandler,
      active: activeMarket === "OU25",
    },
    {
      name: "1x2BTTS",
      text: "Match Result & BTTS",
      onClick: onClickHandler,
      active: activeMarket === "1x2BTTS",
    },
  ];

  // Passed to ButtonGroup
  const playerButtons = [
    {
      name: "AGS",
      text: "Anytime Goalscorer",
      onClick: onClickHandler,
      active: activeMarket === "AGS",
    },
    {
      name: "FGS",
      text: "First Goalscorer",
      onClick: onClickHandler,
      active: activeMarket === "FGS",
    },
    {
      name: "P1+SOT",
      text: "1+ Shots On Target",
      onClick: onClickHandler,
      active: activeMarket === "P1+SOT",
    },
    {
      name: "PTBC",
      text: "To Be Carded",
      onClick: onClickHandler,
      active: activeMarket === "PTBC",
    },
  ];

  const allOptions = currentTeams.map(({ teamName, id }) => {
    return {
      id: id,
      name: teamName,
    };
  });

  allOptions.unshift({
    id: "all",
    name: "All teams",
  });

  const filteredTableData =
    activeTeam === "all"
      ? tableData
      : filterPlayerMarketByTeam(tableData, activeTeam);

  const tableDataToRender = tableMinimised
    ? filteredTableData.slice(0, minimisedRowCount)
    : filteredTableData;

  // Used to correctly identify active selections
  const hasButtonBeenPressed = (
    currentRow: CommonAndEventIdPair
  ): "true" | "false" => {
    const activeResult = activeCommonAndEventIdPairs.findIndex(
      ({ commonID, eventId }) =>
        commonID === currentRow.commonID && eventId === currentRow.eventId
    );
    return activeResult > -1 ? "true" : "false";
  };

  const smartAccaLinkWrapper = (children: ReactElement) => (
    <LinkNoPrefetch
      href={"/smart-acca"}
      className={styles.smartAccaCompetitionSmartAccaLink}
    >
      {children}
    </LinkNoPrefetch>
  );
  const brand = getActiveBrand();

  return (
    <div className={styles.smartAccaCompetitionWrapper} ref={smartAccaRef}>
      <div className={styles.smartAccaCompetitionHeaderContainer}>
        <div className={styles.smartAccaCompetitionHeader}>
          {competitionId && (
            <Image
              src={competitionLogoSVG(competitionId)}
              alt=""
              width={23}
              height={23}
            />
          )}
          <h2
            className={styles.smartAccaCompetitionHeading}
          >{`${capitalisedValidType} stats betting`}</h2>
        </div>
        {validType === "player" && currentTeams.length > 0 && (
          <>
            <Select
              defaultOption={allOptions[0]}
              optionsList={allOptions}
              onClick={onSelection}
            />
          </>
        )}
      </div>
      <div className={styles.smartAccaCompetitionButtonGroupWrapper}>
        <ButtonGroup
          buttons={validType === "team" ? teamButtons : playerButtons}
        />
      </div>
      {tableDataToRender.length > 0 ? (
        <table className={styles.smartAccaCompetitionTable}>
          <thead>
            <tr>
              {tableHeaders.map((heading) => (
                <th
                  className={styles.smartAccaCompetitionTableHeader}
                  key={heading}
                  id={heading.toLowerCase() === "fixture" ? "fixture" : ""}
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableDataToRender.map((row, i) => {
              // This shows the league table position which starts at 1 not zero
              const rowPosition = i + 1;
              return (
                <tr
                  className={styles.smartAccaCompetitionTableHeaderRow}
                  key={row._id}
                >
                  <td className={styles.smartAccaCompetitionCell}>
                    <div
                      className={styles.smartAccaCompetitionRowNameContainer}
                    >
                      <span
                        className={styles.smartAccaCompetitionPositionSpan}
                        aria-label="table position"
                      >
                        {rowPosition}
                      </span>
                      <Image
                        src={idToTeamBadgeImageUrl(row.teamId)}
                        alt={validType === "player" ? row.teamName : ""}
                        width={20}
                        height={20}
                      />
                      <span
                        className={styles.smartAccaCompetitionRowResultName}
                      >
                        {type === "player" ? row.playerName : row.teamName}
                      </span>
                    </div>
                  </td>
                  <td
                    className={styles.smartAccaCompetitionCell}
                  >{`${fixedTo1DecimalPlace(row.probability)}%`}</td>
                  <td className={styles.smartAccaCompetitionCell}>
                    {row.numberOfMatches}
                  </td>
                  <td className={styles.smartAccaCompetitionCell}>
                    {row.numberOfWins}
                  </td>
                  <td className={styles.smartAccaCompetitionCell} id="opponent">
                    <span className={styles.smartAccaCompetitionRowResultName}>
                      {`${
                        row.nextFixture.opponent
                      } (${row.nextFixture.teamPosition[0].toUpperCase()})`}
                    </span>
                  </td>
                  <td className={styles.smartAccaCompetitionOddsButtonCell}>
                    <button
                      className={styles.smartAccaCompetitionOddsButton}
                      onClick={() => {
                        dispatch({
                          type: "ADD",
                          commonAndSelectionIdPair: {
                            eventId: row.bestOdds.mappedEventId,
                            commonID: row.bestOdds.commonID,
                          },
                          source: "Smart Acca Competition",
                        });
                      }}
                      aria-pressed={hasButtonBeenPressed({
                        commonID: row.bestOdds.commonID,
                        eventId: row.bestOdds.mappedEventId,
                      })}
                    >
                      {getFormattedOdds(row.bestOdds, oddsFormat)}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className={styles.noStatsPara}>No stats to display</p>
      )}
      <div className={styles.smartAccaCompetitionFooter}>
        <ConditionalWrapper
          wrapper={smartAccaLinkWrapper}
          condition={brand !== FP_BRAND}
        >
          <div className={styles.smartAccaCompetitionSmartAccaLinkContainer}>
            <span className={styles.smartAccaCompetitionSmartAccaSpan}>
              Powered by
            </span>
            {colourMode === "dark" ? (
              <Image
                src={SmartAccaLogo}
                alt="Smart Acca"
                width={72}
                height={10}
              />
            ) : (
              <Image
                src={SmartAccaLogoBlack}
                alt="Smart Acca"
                width={85}
                height={10}
              />
            )}
          </div>
        </ConditionalWrapper>
        {results.length > minimisedRowCount && (
          <button
            className={styles.smartAccaCompetitionViewMoreButton}
            onClick={toggleTableData}
          >
            {tableMinimised ? "View More" : "Show Less"}
            {tableMinimised ? <AngleDownIcon /> : <AngleUpIcon />}
          </button>
        )}
      </div>
    </div>
  );
};
