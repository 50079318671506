import {
  SmartAccaCompetitionResponse,
  SmartAccaCompetitionType,
  SmartAccaMatchResponse,
  SuggestedSmartAccaResponse,
} from "@/types/api/smartAcca";
import { getActiveBrand } from "@/lib/brand";
import { getDataFromPathAndQueryParams } from "@/lib/http/helpers";

interface GetSmartAccaCompetitionWidgetData {
  competitionId: string;
  type: SmartAccaCompetitionType;
  market: string;
}

export const getSmartAccaCompetitionWidgetData = async ({
  competitionId,
  type,
  market,
}: GetSmartAccaCompetitionWidgetData): Promise<SmartAccaCompetitionResponse> => {
  const path = `smartacca/widgets/competitions/${competitionId}/${type}`;
  const queryParams = {
    market,
  };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};

export const getSmartAccaMatchWidgetData = async (
  fixtureID: string,
  basedOn: string,
  expiredFixtureGeneration?: boolean
): Promise<SmartAccaMatchResponse> => {
  const path = `smartacca/fixtures/${fixtureID}`;
  const queryParams = {
    basedOn,
    expiredFixtureGeneration,
  };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};

export const getSuggestedSmartAccaWidgetData = async (
  locale: string,
  date?: string,
  competitionId?: string,
  fixtureId?: string
): Promise<SuggestedSmartAccaResponse> => {
  const brand = getActiveBrand();
  const path = `smartacca/suggested`;
  const queryParams = {
    brand,
    location: locale.toUpperCase(),
    date,
    matchId: fixtureId,
    competitionId,
  };

  return await getDataFromPathAndQueryParams({
    path,
    queryParams,
    isOddsPlatformRequest: true,
  });
};
