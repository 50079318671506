import { ReactElement, MouseEvent } from "react";
import { StyledButton } from "@/components/Button/Button.style";

interface ButtonProps {
  name: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (e: MouseEvent<HTMLButtonElement>["currentTarget"]["name"]) => void;
  children: string;
  active?: boolean;
  toggle?: { enabled: boolean; isToggled: boolean };
}

export const Button = ({
  name,
  onClick,
  children,
  active = false,
  toggle,
}: ButtonProps): ReactElement => {
  const toggleEnabled = toggle ? toggle.enabled : false;

  const onClickHandler = ({
    currentTarget: { name },
  }: MouseEvent<HTMLButtonElement>) => onClick(name);

  const defaultProps = {
    name,
    onClick: onClickHandler,
    active,
  };
  const props =
    toggle && toggleEnabled
      ? { ...defaultProps, "aria-pressed": toggle.isToggled }
      : { ...defaultProps };

  return <StyledButton {...props}>{children}</StyledButton>;
};
