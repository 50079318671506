import { MarketType } from "@/types/api/market";
import {
  ANYTIME_GOALSCORER,
  MATCH_RESULT,
  PLAYER_MARKETS,
  TEAM_MARKETS,
} from "@/constants/markets";
import {
  SmartAccaCompetitionType,
  SmartAccaCompetitionResult,
} from "@/types/api/smartAcca";

interface ValidateSmartAccaMarket {
  type: SmartAccaCompetitionType;
  market?: MarketType;
}

export const validateSmartAccaMarket = ({
  type,
  market,
}: ValidateSmartAccaMarket): MarketType => {
  if (type === "player") {
    return market && PLAYER_MARKETS.includes(market)
      ? market
      : ANYTIME_GOALSCORER;
  }

  return market && TEAM_MARKETS.includes(market) ? market : MATCH_RESULT;
};

export const filterPlayerMarketByTeam = (
  playerResults: SmartAccaCompetitionResult[],
  teamId: string
): SmartAccaCompetitionResult[] =>
  playerResults.filter((result) => result.teamId === teamId);
