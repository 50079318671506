import { ReactElement, useRef, useState } from "react";
import { useDetectOutsideClick } from "@/hooks/DetectOutsideClick";
import themeSelector from "@/brands/theme";
import { getActiveBrand } from "@/lib/brand";
import DropdownIcon from "@/components/icons/DropdownIcon";
import styles from "@/components/Select/Select.module.scss";

export interface Option {
  id: string;
  name: string;
}

interface SelectProps {
  defaultOption: Option;
  optionsList: Option[];
  // eslint-disable-next-line no-unused-vars
  onClick: (e: Option) => void;
}

export const Select = ({
  defaultOption,
  optionsList,
  onClick,
}: SelectProps): ReactElement => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option>(defaultOption);

  const toggleOptionsVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleOptionSelect = (selectedOption: Option) => {
    setSelectedOption(selectedOption);
    // Hide other options
    toggleOptionsVisibility();
  };

  // Close options list when clicking outside it
  const defaultOptionRef = useRef<HTMLButtonElement>(null);
  const optionsListRef = useDetectOutsideClick(
    toggleOptionsVisibility,
    defaultOptionRef
  );

  const brand = getActiveBrand();
  const theme = themeSelector(brand);

  return (
    <div className={styles.selectContainer}>
      <button
        className={styles.button}
        ref={defaultOptionRef}
        onClick={toggleOptionsVisibility}
      >
        {selectedOption.name}
        <DropdownIcon fill={theme?.select?.fontColor} />
      </button>
      {isVisible && (
        <div className={styles.optionsContainer}>
          <ul
            className={styles.optionsList}
            ref={optionsListRef as React.RefObject<HTMLUListElement>}
            data-testid="dropdown-list"
          >
            {optionsList.map((option) => (
              <li
                key={option.id}
                className={
                  styles[
                    `listItem${
                      selectedOption.id === option.id ? "--active" : ""
                    }`.trim()
                  ]
                }
                onClick={() => {
                  handleOptionSelect(option);
                  onClick(option);
                }}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
