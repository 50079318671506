import styled from "styled-components";

export const StyledButton = styled.button<{ active: boolean }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme, active }) =>
    active ? theme.activeElement : theme.inactiveElement};
  border: none;
  border-radius: 1rem;
  font-family: var(--font-inter);
  font-size: 0.75rem;
  color: ${({ theme, active }) =>
    active ? theme.activeTabButtonText : theme.defaultText};
`;
